
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { Role } from '../models/role';
import { User } from '../models/user';
import { AuthCredentials } from '../models/auth-credentials';
import { first, map } from 'rxjs/operators';

import { AuthConfig } from '../models/auth.config';
import { RoleKey } from '../models/role-key';
import { AUTH_CONFIG } from '../tokens/auth.config.token';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { LocalStorageService } from '../../../common/services/local-storage.service';
import { Group } from '../../../common/models/group';

const DEFAULT_REDIRECT:string = "/rozliczenia";
const OPERATOR_REDIRECT: string = DEFAULT_REDIRECT;
const CONTRACTOR_OPERATOR_REDIRECT: string = DEFAULT_REDIRECT;
const ACCOUNTING_REDIRECT: string = "/faktury";

interface AuthResponse {
    token: string;
    user: User;
    password_expired: boolean;
    messages?: string[];
}

@Injectable()
export class AuthService {

    protected appURL: string = environment.appURL;
    protected autoLogin = environment.autoLogin; 
    protected authApiURL: string;
    protected authUserKey: string;
    protected authTokenKey: string;
    protected userSubject$!: BehaviorSubject<User | null>;
    
    public redirectUrl: string = DEFAULT_REDIRECT;
    public user$!: Observable<User | null>;

    constructor(
        protected router: Router,
        protected http: HttpClient,
        @Inject(LocalStorageService) protected localStorage: LocalStorageService,
        @Inject(AUTH_CONFIG) private config: AuthConfig
    ) {
        this.authApiURL = config.authApiURL;
        this.redirectUrl = config.defaultRedirect || DEFAULT_REDIRECT;
        this.authTokenKey = config.authTokenKey;
        this.authUserKey = config.authUserKey;

        this.init();
    }

    protected init() {
        let user: User | null = null;

        if(this.autoLogin) {
                   
            user = this.localStorage.getObject(this.authUserKey, false);
            if(user) {
                this.localStorage.userKey = user.email; 
            }
            // user = {
            //     id: 1,
            //     firstname: "Marzena lub Ola",
            //     lastname: "Koordynator w biurze magazynu",
            //     email: "...",
            //     // user_status: UserStatus
            //     roles: [
            //         // {
            //         //     id: 6,
            //         //     logic_name: "coordinator_wrh",
            //         //     name: "Koordynator w biurze magazynu",
            //         // },
            //         {
            //             id: 4,
            //             logic_name: "operator_wrh",
            //             name: "Operator w magazynie regeneracji",
            //         }  
            //     ],
            //     // created_at?: string;
            //     // last_login_at?: string;
            // };
        } else {
           // console.log("!Auto login");
        }

        this.userSubject$ = new BehaviorSubject<User | null>(user);
        this.user$ = this.userSubject$.asObservable();
    }   

    public get isLogged(): boolean {
        return this.userSubject$.value !== null;
    }

    public get user(): User | null {
        return this.userSubject$.value;
    }

    public set userRole(key: string) {
        const user = Object.assign(
            {}, 
            this.user,
            {
                roles: [{
                    id: 0,
                    logic_name: key,
                    name: "Testowa",
                }]
            }
        );
        
        this.userSubject$.next(user);
    }

    /**
     * Czy użytkownik pełni jedną z ról?
     */
    public performsOneOfTheRoles(roles: RoleKey[]): boolean {
        if(this.user) {
            let userRoles: RoleKey[] = this.user.roles;

            return roles.length == 0 || roles.some(
                (roleKey) => {
                    return userRoles.find((item: string) => item == roleKey);
                }
            )
        }

        return false;
    }

    public login(credentials: AuthCredentials, redirect: boolean = true, zapamietaj: boolean = false) {
        return this.http.post<any>(`${this.authApiURL}/login`, credentials)
            .pipe(
                map(
                    (authResesponse: AuthResponse) => {
                        let user: User = authResesponse.user;
                        
                        this.localStorage.userKey = user.email;
                        this.localStorage.set(this.authTokenKey, authResesponse.token, false);
                        this.localStorage.setObject(this.authUserKey, authResesponse.user, false);
                        this.userSubject$.next(user);
                        

                        this.doRedirect(user);
        
                        return user;
                    }
                )
            );
    }

    public testLogin(user: User) {
        this.userSubject$.next(user);
        
        this.doRedirect(user);
    }

    private doRedirect(user: User) {
        switch(user.roles[0]) {
            case RoleKey.operator:
                this.redirectUrl = OPERATOR_REDIRECT;
                break;
            case RoleKey.contractor_operator:
                this.redirectUrl = CONTRACTOR_OPERATOR_REDIRECT;
                break;
            case RoleKey.accounting:
                this.redirectUrl = ACCOUNTING_REDIRECT;
                break;
            default:
                this.redirectUrl = DEFAULT_REDIRECT;
        }   

        this.router.navigate([this.redirectUrl]);
    }   

    private parseParams(querystring: string): {} {
        const params = querystring 
            ? querystring.split("&").reduce(
                function(prev: any, curr: any, i, arr) {
                    var p: string[] = curr.split("=");
                    prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
                    return prev;
                }, 
                {}
            )
            : {};

        return params;
    };

    public logout() {
        this.localStorage.remove(this.authUserKey, false);
        this.localStorage.remove(this.authTokenKey, false);
                        
        this.localStorage.userKey = "";
        this.userSubject$.next(null);
        this.router.navigate(['/auth/login']);
    }

    public setContext(group: Group) {
        if(this.user) {
            this.user.namedShopGroup = group;
            this.localStorage.setObject(this.authUserKey, this.user, false);
        }
    }

    

    // public forgottenPassword(params: any) {
    //     return this.http.post<any>(
    //         `${this.authApiURL}/forgotten_password`, 
    //         params
    //     )
    //         .pipe(
    //             first(),
    //             map(
    //                 (res: any) => {
    //                     return res;
    //                 }
    //             )
    //         );
    // }

    // public passwordReset(params: any) {
    //     return this.http.post<any>(
    //         `${this.config.authApiURL}/password_reset`, 
    //         params
    //     )
    //         .pipe(
    //             map(
    //                 (res: any) => {
    //                     return res;
    //                 }
    //             )
    //         );
    // }

    // public changePassword(params: any) {
    //     return this.http.post<any>(
    //         `${this.config.authApiURL}/change_password`, 
    //         params
    //     )
    //         .pipe(
    //             map(
    //                 (res: any) => {
    //                     return res;
    //                 }
    //             )
    //         );
    // }
}
