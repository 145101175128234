<div [ngClass]="{'flex-col': mode == 'horizontal'}" class="relative flex justify-between h-full grow-1">
    <div #myCanvasContainer class="w-full h-full grow">
        <div class=" flex items-center justify-center relative h-full">
            <canvas class="absolute" #myCanvas ></canvas>
            <div *ngIf="loading && selectedImageId > -1" class="flex justify-center rotating text-gray-500"><mat-icon>autorenew</mat-icon></div>
        </div>
    </div>
    <div *ngIf="imageIndex < 0" #info class="absolute flex items-center justify-center">
        <div class="flex flex-col items-center justify-center">
            <mat-icon class="mt-2" style="transform: scale(2);">error_outline</mat-icon>
            <p class="px-4 pt-4 text-center">Z rozliczeniem nie połączono jeszcze żadnego protokołu.</p>
            <button (click)="chooseProtocol()" *ngIf="chooseProtocolButton" mat-flat-button color="primary"><mat-icon>radio_button_checked</mat-icon> Wskaż protokół</button>
        </div>
    </div>
    <div 
        [ngClass]="{'border-t overflow-x-auto overflow-y-hidden h-20 justify-center': mode == 'horizontal', 'border-l flex-col justify-begin overflow-x-hidden overflow-y-scroll w-20' : mode == 'vertical'}"
        class="flex p-2 grow-0"
        *ngIf="menu"
    >
        <button (click)="zoomIn()" mat-icon-button color="primary" aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>zoom_in</mat-icon>
        </button>
        <button (click)="zoomOut()" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
            <mat-icon>zoom_out</mat-icon>
        </button>
        <button (click)="resetView()" mat-icon-button color="primary" aria-label="Example icon button with a menu icon">
            <mat-icon>restart_alt</mat-icon>
        </button>
        <!-- <button *ngIf="resize" (click)="expandCollapse()" mat-icon-button color="primary" aria-label="Example icon button with a heart icon">
            <mat-icon *ngIf="!zoomed">open_in_full</mat-icon>
            <mat-icon *ngIf="zoomed">close_fullscreen</mat-icon>
        </button> -->
        <button *ngIf="fullScreen && !previewOnly" (click)="fullScreenMode()" mat-icon-button color="primary" aria-label="Example icon button with a heart icon">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </div>
    <div
        *ngIf="thumbnails"
        [ngClass]="{'h-24 px-2 justify-center': mode == 'horizontal', 'flex-col w-64 pr-4 overflow-y-scroll bg-white' : mode == 'vertical'}" 
        class="flex grow-0 "
    >
        <div class="flex flex-col items-center justify-center mb-4 " *ngFor="let imageSource of imageSources; let i = index">
            <div 
                [ngClass]="{'border-orange-500': i == imageIndex, 'border-4':  (mode == 'vertical' && i == imageIndex), 'border-t-4 mx-2': mode == 'horizontal'}" 
                (click)="selectImage(i)" 
                 
                class="flex border flex-col items-center cursor-pointer justify-center relative w-full overflow-hidden" style="aspect-ratio: 3 / 4;"
            >                         
                <img (error)="protocolLoaded(imageSource)" (load)="protocolLoaded(imageSource)" [src]="imageSource.path + '&size=small'" alt="" class="absolute"/>
                <div *ngIf="!imageSource.loaded" class="flex justify-center rotating text-gray-500"><mat-icon>autorenew</mat-icon></div>
            </div>
            <mat-radio-button *ngIf="!previewOnly" [checked]="imageSource.id == selectedImageId" class="mt-1" color="primary" value="{{imageSource.id}}" (change)="selectProtocol(imageSource.id)"></mat-radio-button>
        </div>
    </div>
    
</div>

