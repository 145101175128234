import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { Observable, map } from 'rxjs';
import { Group } from '../../models/group';
import { Page } from '../../models/lists/page';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {

    private apiURL                  = environment.apiURL;
    private groupsPathPart          = environment.groupsPathPart;
    private shopsInGroupsPathPart   = environment.shopsInGroupsPathPart;

    constructor(
        protected httpClient: HttpClient
    ) { }
    /**
     * Pobiera grupę sklepów o wskazanym id
     * 
     * @param settlementId
     * @returns 
     */
    public getGroup(groupId: number): Observable<Group> {
        return this.httpClient.get(
            `${this.apiURL}/${this.groupsPathPart}/${groupId}`,
             {}
        ).pipe(
            map( 
                (groupResponse: any) => {
                    return this.prepareGroup(groupResponse.data)
                }
            )
        ) as Observable<Group>;
    }

    public getGroups(params: any): Observable<Page<Group>> {

        params = Object.assign(
            params,
            {
                ///page: 1
            }
        );

        if(!params.column) {
            params.column = "id",
            params.order = "ASC"
        }
        
        return this.httpClient.get(
            `${this.apiURL}/${this.groupsPathPart}`,
            {
                params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (groupsResponse: any) => {
                    const groups: Group[] = [];
                    groupsResponse.data.forEach((group: Group) => {
                        groups.push(
                            this.prepareGroup(group)
                        );
                    });

                    return {
                        data: groups,
                        meta: groupsResponse.meta 
                            ? groupsResponse.meta
                            : {
                                current_page: 1,
                                from: 1,
                                last_page: 1,
                                per_page: groups.length,
                                to: groups.length,
                                total: groups.length
                            }
                    }
                }
            )
        ) as Observable<Page<Group>>;
    }

    public createGroup(params: any): Observable<Group> {
        return this.httpClient.post(
            `${this.apiURL}/${this.groupsPathPart}`,
            params
        ).pipe(
            map( 
                (groupResponse: any) => {
                    return this.prepareGroup(groupResponse.data);
                }
            )
        ) as Observable<Group>;
    }

    public updateGroup(groupId: Number, params:any): Observable<any> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.groupsPathPart}/${groupId}`,
            params
        ) as Observable<any>;
    }

    public deleteGroup(groupId: Number): Observable<any> {
        return this.httpClient.delete(
            `${this.apiURL}/${this.groupsPathPart}/${groupId}`,
        ) as Observable<any>;
    }

    prepareGroup(groupData: any): Group {
        return {
            id: Number(groupData.id),
            name: groupData.name,
            mpks: groupData.mpks,
            author: {
                firstname: groupData.author.firstname,
                lastname: groupData.author.lastname
            }
            // company: null
        }
    }

    public getShopsFile(groupId: number): Observable<any>  {
        return (
            this.httpClient.get(
                `${this.apiURL}/${this.shopsInGroupsPathPart}/${groupId}`,
                {
                    responseType: 'blob' as 'json',
                    headers: {
                        "accept": "text/plain"
                    }
                }
            )
        ).pipe(
            map(
                (response: any) => {
                    // https://stackoverflow.com/questions/51682514/angular-how-to-download-a-file-from-httpclient
                    let dataType = response.type;
                    let binaryData = [];
                    binaryData.push(response);
                    let downloadLink = document.createElement('a');
                    downloadLink.setAttribute('target', '_blank');
                    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                    downloadLink.setAttribute('download', "sklepy_w_grupie_" + groupId + "" + new Date().toLocaleDateString() + ".txt");
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                } 
            )
        );
    }

    private mapNullProperties(paramsWithNull: any) {
        const paramsWithoutNull = {...paramsWithNull};

        Object.keys(paramsWithoutNull).forEach(key => {
            if (paramsWithoutNull[key] === null || paramsWithoutNull[key] === -1 || paramsWithoutNull[key] === "-1") {
                paramsWithoutNull[key] = '';
            }
        });

        return paramsWithoutNull;
    }
}
