import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuardService } from './services/user-guard.service';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
    {
        path: 'auth',
        canActivateChild: [ UserGuardService ],
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            // {
            //     path: 'forgotten_password',
            //     component: PasswordRecoveryComponent
            // },
            // {
            //     path: 'password_reset/:token',
            //     component: PasswordResetComponent
            // }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
