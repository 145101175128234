import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReckoningDialogComponent } from './modules/reckonings/components/reckoning/reckoning-dialog/reckoning-dialog.component';
import { SelectMpkGroupDialogComponent } from './components/select-mpk-group/select-mpk-group-dialog.component';
import { AuthService } from './modules/auth/services/auth.service';
import { Settlement } from './common/models/settlement';
import { RoleKey } from './modules/auth/models/role-key';
import { environment } from '../environments/environment';
import { CountsService } from './common/services/counts.service';
import { SettlementsToReact } from './common/models/counts/settlements-to-react';
import { ShopCount } from './common/models/counts/shop_count';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public env: string = environment.environmentName;
    public version: string = environment.version;
    public title: string = 'Frioarte Service Panel';

    public settlementsToReact: SettlementsToReact | null = null;
    public shopsWithoutContexts: ShopCount | null =  null;
    public shopsWithoutGuaranteeDates: ShopCount | null =  null;

    public ROLE_KEYS = RoleKey;

    public roleName: 'FRIOARTE' | 'FRIVEN' = 'FRIVEN';

    constructor(
        public dialog: MatDialog,
        protected router: Router,
        public authService: AuthService,
        public countsService: CountsService
    ) {}

    ngOnInit(): void {
        this.countsService.settlementsToReact$.subscribe(
            (res: SettlementsToReact | null) => {
                this.settlementsToReact = res;
            }
        );

        this.countsService.shopsWithoutContexts$.subscribe(
            (res: ShopCount | null) => {
                this.shopsWithoutContexts = res;
            }
        );

        this.countsService.shopsWithoutGuaranteeDates$.subscribe(
            (res: ShopCount | null) => {
                this.shopsWithoutGuaranteeDates = res;
            }
        );
    }

    getSettlementsToReact() {
        return this.settlementsToReact ? `${this.settlementsToReact.waiting_with_context}/${this.settlementsToReact.waiting}` : "..."; 
    }

    selectMpkGroupDialog(): void {
        const dialogRef = this.dialog.open(SelectMpkGroupDialogComponent, {
            maxWidth: '95vw',
            maxHeight: '95vh',
            width: '25vw'
        });

        dialogRef.afterClosed().subscribe(() => {
            
        });
    }

    isAvailiableFor(roleKeys: RoleKey[]): boolean {
        return this.authService.performsOneOfTheRoles(roleKeys);
    }
}
