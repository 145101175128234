<!-- <h1>Frioarte Rozliczenia - v. 0.1.0</h1> -->
<div class="flex w-full bg-white h-full">
    <nav class="fuse-vertical-navigation p-4 overflow-y-auto">
        <div class="text-white flex px-2 w-full mt-6 mb-8">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div class="ml-2">Frioarte Principal <br/> Rozliczenia</div>
        </div>
        <ng-container *ngIf="authService.isLogged">
            <div class="border-b border-white border-dotted mb-4">
                <div class="text-base p-3 text-gray-300 flex items-center justify-between" *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator, ROLE_KEYS.operator])">
                    <div>
                        <div class="text-xs font-bold text-emerald-500 uppercase mb-2">Wybrany kontekst:</div>
                        <div class="text-white">
                            <span *ngIf="authService.user && authService.user.namedShopGroup">{{authService.user.namedShopGroup.name}}</span>
                            <span *ngIf="!authService.user || !authService.user.namedShopGroup">Brak</span>
                        </div>
                    </div>
                    <div class="text-gray-400">
                        <button mat-icon-button matTooltip="Zmień kontekst" color="primary" (click)="selectMpkGroupDialog()" class="cursor-pointer">
                            <mat-icon>track_changes</mat-icon>
                        </button>
                    </div>
                    
                </div>
                <!-- <div *ngIf="shopsWithoutContexts && shopsWithoutContexts.count != '0'" class="px-3 pb-3">
                    <div class="bg-emerald-800 p-1 text-white flex justify-center">Sklepy bez kontekstu: {{shopsWithoutContexts.count}}</div>
                </div> -->
            </div>

            <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500" *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator])">
                Zgłoszenia
            </div>
            <ul class="fap-sp-main-menu">
                <li *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator])">
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLinkActiveOptions]="{'exact': true}" [routerLink]="['/zgloszenia']">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
                        </svg>
                            
                        <span>Zgłoszenia</span>
                    </a>
                </li>
            </ul>
            <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-6" *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator, ROLE_KEYS.operator])">
                Rozliczenia
            </div>
            <ul class="fap-sp-main-menu" *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator, ROLE_KEYS.operator])">
                <li *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator])">
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLinkActiveOptions]="{'exact': true}" [routerLink]="['/rozliczenia/w-przygotowaniu']">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>     
                        <span>W trakcie przygotowania</span>
                    </a>
                </li>
                <li *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator, ROLE_KEYS.operator])">
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLinkActiveOptions]="{'exact': true}"  [routerLink]="['/rozliczenia']">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                        </svg>     
                        <span matBadge="{{getSettlementsToReact()}}" [matBadgeHidden]="!settlementsToReact" matBadgeOverlap="false" class="demo-section">Do uzgodnienia</span>
                    </a>
                </li>
                <li *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator, ROLE_KEYS.operator])">
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/rozliczenia/uzgodnione']">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                        </svg>
                        
                        <span>Zaakceptowane</span>
                    </a>
                </li>
                <li *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator, ROLE_KEYS.operator])">
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/rozliczenia/zafakturowane']">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" />
                        </svg>
                        
                        <span>Zafakturowane</span>
                    </a>
                </li>
            </ul>
            <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-6">
                Faktury
            </div>
            <ul class="fap-sp-main-menu">
                <li *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator])">
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/faktury/wsady-do-faktur']" [routerLinkActiveOptions]="{'exact': true}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                        </svg>  
                        <span>Przekaż fakturę</span>
                    </a>
                </li>
                <li>
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/faktury']" [routerLinkActiveOptions]="{'exact': true}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                        </svg>
                        <span>Faktury</span>
                    </a>
                </li>
                <li *ngIf="isAvailiableFor([ROLE_KEYS.operator])">
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/faktury/zestawienia']" [routerLinkActiveOptions]="{'exact': true}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3" />
                        </svg>
                          
                        <span>Zestawienia dla Fuste</span>
                    </a>
                </li>
            </ul>
            <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-6" *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator, ROLE_KEYS.operator])">
                Konteksty
            </div>
            <ul class="fap-sp-main-menu" *ngIf="isAvailiableFor([ROLE_KEYS.contractor_operator, ROLE_KEYS.operator])">
                <li>
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/grupy']">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                        </svg>  
                        <span>Konteksty</span>
                    </a>
                </li>
            </ul>
            <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-6" *ngIf="isAvailiableFor([ROLE_KEYS.operator])">
                Sklepy
            </div>
            <ul class="fap-sp-main-menu" *ngIf="isAvailiableFor([ROLE_KEYS.operator])">
                <li>
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/sklepy']">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                        </svg>
                          

                        <span matBadge="{{shopsWithoutGuaranteeDates?.count}}" matBadgeOverlap="false" class="demo-section">Sklepy bez daty gwarancji</span>
                    </a>
                </li>
            </ul>
            <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-6" *ngIf="isAvailiableFor([ROLE_KEYS.operator])">
                Naprawy i części
            </div>
            <ul class="fap-sp-main-menu" *ngIf="isAvailiableFor([ROLE_KEYS.operator])">
                <li>
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/konfiguracja/czesci']">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z" />
                        </svg>    

                        <span class="demo-section">Typy części</span>
                    </a>
                </li>
            </ul>
            <!-- <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-8">
                Eksperymenty
            </div>
            <ul class="fap-sp-main-menu">
                <li>
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/playground']">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                        </svg>
                        <span>Eksperymenty</span>
                    </a>
                </li>
                <li>
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/playground/layout']">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                        </svg>  
                        <span>Layout</span>
                    </a>
                </li>
            </ul> -->
            <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-6 pt-4 border-t border-white border-dotted ">
                    {{authService.user?.firstname}} {{authService.user?.lastname}}
            </div>
            <ul class="fap-sp-main-menu">
                <li>
                    <a (click)="authService.logout()"  class="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                        </svg>  
                        <span>Wyloguj</span>
                    </a>
                </li>
            </ul>
            <!-- <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-8">
                Integracja
            </div>
            <ul class="fap-sp-main-menu">
                <li>
                    <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/integracja']">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                        </svg>  
                        <span>Serwisy</span>
                    </a>
                </li>
            </ul> -->
        </ng-container>
        <div  class="z-20 px-3 text-gray-500">
            <!-- <button (click)="roleName == 'FRIVEN' ? roleName = 'FRIOARTE' : roleName = 'FRIVEN'">Przełącz użytkownika (na {{roleName == 'FRIVEN' ? 'FRIOARTE' : 'FRIVEN'}})</button> -->
            <div class="mt-6 bg-emerald-800 text-white flex justify-center p-1">Wersja: {{version}} | {{env}}</div>
        </div>
    </nav>

    <div class="w-full h-full">
        <router-outlet></router-outlet>
    </div>

</div>

