import { Injectable } from '@angular/core';
import { 
    HttpEvent, HttpRequest, HttpHandler, 
    HttpInterceptor, HttpErrorResponse, HttpParams 
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../../modules/auth/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../../components/error-dialog/error-dialog.component';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        public dialog: MatDialog
    ) { }
        
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const rparams = request.params;
        // rparams.delete('x');
        // rparams.append("y", 1);

        // request = request.clone(
        //     {
        //         params: request.params.delete('x'),
        //     }
        // )

        // const xxx = request.params.get('x');
        // //request.params.append("y", 1);
        // //request.params.delete('x');
        
        return next.handle(request).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    console.log("HttpErrorInterceptor - obsługa błędu!");
                    //this.logsService.addItem("error", JSON.parse(JSON.stringify(error)));

                    if (error.status === 504) {
                        this.showError(new HttpErrorResponse(
                            {
                                error: {
                                    message: "Brak odpowiedzi serwera - sprawdź czy masz połączenie z Internetem."
                                }
                            }
                        ));
                        return EMPTY;
                    } else if (error.status === 0) {
                        this.showError(new HttpErrorResponse(
                            {
                                error: {
                                    message: "Błąd komunikacji z serwerem."
                                }
                            }
                        ));
                        
                        return EMPTY;
                    } else if (error.status === 401) {
                        // 401 Unauthorized ()
                        
                        this.authService.logout();
                        this.showError(new HttpErrorResponse(
                            {
                                error: {
                                    message: error.error.message == "Unauthenticated." ? "Zostałeś wylogowany - zaloguj się ponownie." : "Próba nieuprawnionego dostępu - zaloguj się ponownie."
                                }
                            }
                        ));

                        return EMPTY;
                    } else if (error.status === 422) { 
                        // 422 Unprocessable Entity
                        this.showError(error);
                        return throwError(() => error);
                        // return EMPTY;
                    } else if (error.status === 404) { 
                        // 404 Not Found
                        this.showError(new HttpErrorResponse(
                            {
                                error: {
                                    message: "Wskazany zasób nie jest już dostępny."
                                }
                            }
                        ));
                        return EMPTY;
                    } else if (error.status === 409) { 
                        // 409 Conflict
                        this.showError(error);
                        return EMPTY;
                    } else {
                        this.showError(error);
                        return EMPTY;
                    }
                }
            )
        );    
    }

    protected showError(error: HttpErrorResponse) {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
                message: error.error.message ? error.error.message : error.error.error ? error.error.error : null,
                errors: error.error.errors ? error.error.errors : null
            },
            maxWidth: '95vw',
            maxHeight: '95vh'
        }); 
    }
}