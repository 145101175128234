import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { first } from 'rxjs/operators';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { ClientService } from '../../services/client.service';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';

const ERROR_MESSAGES = new Map<string, Map<string, string>>([
    [
        'email', new Map<string, string>([
            [ 'required', 'Wprowadź adres email' ]
        ]),
    ],
    [
        'password', new Map<string, string>([
            [ 'required', 'Wprowadź hasło' ]
        ]),
    ]
]);

@Component({
    selector: 'div.nfi-view',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public env: string = environment.environmentName;

    @ViewChild('loginField')
    loginField!: ElementRef;

    @ViewChild('passwordField')
    passwordField!: ElementRef;


    public version = environment.version;
    public autoLogin = environment.autoLogin; 
    public testUsersFrio: { name: string, email: string, password: string }[] | null = environment.testUsersFrio;
    public testUsersFriwen: { name: string, email: string, password: string }[] | null = environment.testUsersFriwen;
    // public testUsers: User[] | null = environment.testUsers;
    
    public companyName = environment.companyName;


    public form!: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        protected authService: AuthService,
        protected clientService: ClientService
    ) { 
        
    }    

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: [null, { validators: [Validators.required], updateOn: 'blur' }],
            password: [null, { validators: [Validators.required], updateOn: 'blur' }],
            device_key: [String(this.clientService.clientId)]
        });

        if(this.autoLogin == true && (this.testUsersFrio || this.testUsersFriwen)) {
            // Spróbuj wczytać token i dane użytkownika
        }

        /*
        if(this.autoLogin == true && this.testUsers) {
            this.testLogin(this.testUsers[0]);
        }
        */
    }

    testLogin(credentials: any) {
        this.login(credentials, false);
    }

    // testLogin(user: User) {
    //     this.authService.testLogin(user);
    // }


    login(credentials: any, onlyValid: boolean = true) {             
       // this.lock("pending");

        this.authService
            .login(
                Object.assign({
                    device_key: String(this.clientService.clientId)
                }, credentials),
                true
            )
            .pipe(first())
            .subscribe(
                {
                    next: () => {
                        //this.unlock("pending");
                    },
                    error: (error) => {
                        //this.unlock("pending");

                        // if(error.status && error.status == 422) {
                        //     throw error;
                        // }
                    },
                    complete: () => {
                       // this.unlock("pending");
                    }
                }
            );
    }
    
    onSubmit() {
        this.form.get('email')?.setValue(this.loginField.nativeElement.value);
        this.form.get('password')?.setValue(this.passwordField.nativeElement.value);

        this.form.updateValueAndValidity();

        if(!this.form?.valid) {
            this.form?.markAllAsTouched();
            return;
        }

        this.login(this.form?.value);
    }

}
