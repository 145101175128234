import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthConfig } from './models/auth.config';
import { AuthService } from './services/auth.service';
import { AUTH_CONFIG } from './tokens/auth.config.token';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        AuthRoutingModule,
        CommonModule,
        SharedModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,  
        MatInputModule,
    ]
})
export class AuthModule { 
    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error(
                "AuthModule is already loaded."
            );
        }
    }

    static forRoot(options: AuthConfig): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: AUTH_CONFIG,
                    useValue: options,
                },
                AuthService
            ],
        };
    }
}
