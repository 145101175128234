<section class="fixed inset-0">
    
    <div class="flex justify-center p-8 fap-ren-one-column-view-content">
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col items-center p-4 border border-gray-300 rounded">
            <div class="flex mt-8">
                
                <mat-form-field>
                    <input #loginField type="text" matInput placeholder="Email" formControlName="email">
                </mat-form-field>
            </div>

            <div class="flex mt-8">
                
                <mat-form-field>
                    <input #passwordField type="password" matInput placeholder="Hasło" formControlName="password">
                </mat-form-field>
            </div>

            <div class="button">
                <button type="submit" mat-flat-button color="primary">Zaloguj się</button>
            </div>

            <div class="my-4 fap-ren-one-column-version">
                Wersja: {{version}} | {{env}}
            </div>

            <ul *ngIf="testUsersFrio" class="pt-4 mt-6 border-t">
                <li *ngFor="let testUser of testUsersFrio" class="mb-2 text-center text-blue-500 cursor-pointer text"><a (click)="testLogin(testUser)">{{testUser.name}} | U: {{testUser.email}} | H: ******** <!-- {{testUser.password}}--> </a></li>
            </ul>
            <ul *ngIf="testUsersFriwen" class="pt-4 mt-6 border-t">
                <li *ngFor="let testUser of testUsersFriwen" class="mb-2 text-center text-blue-500 cursor-pointer text"><a (click)="testLogin(testUser)">{{testUser.name}} | U: {{testUser.email}} | H: ******** <!-- {{testUser.password}}--> </a></li>
            </ul>
        </form>
    </div>
</section>