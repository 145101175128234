import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { Settlement } from '../../models/settlement';
import { Page } from '../../models/lists/page';
import { Ticket } from '../../models/ticket';
import { Shop } from '../../models/shop';
import { Repair } from '../../models/repair';
import { RepairType } from '../../models/repair-type';
import { RepairTypeA } from '../../models/repair-type-a';
import { PartType } from '../../models/part_type';
import { Part } from '../../models/part';
import { Protocol } from '../../models/protocol';
import { Comment } from '../../models/comment';
import { Company } from '../../models/company';
import { SettlementStatus } from '../../models/settlement-status';
import { Account } from '../../models/account';
import { TicketsService } from '../tickets/tickets.service';
import { PartTypesService } from '../parts/part-types.service';
import { Supplier } from '../../models/suppliers';
import { SettlementHistory } from '../../models/settlements-history';

@Injectable({
    providedIn: 'root'
})
export class SettlementsService {

    windowObjectReference: Window | null = null;

    private settlements: Settlement[] = [
       
    ];

    private apiURL                  = environment.apiURL;
    private ticketsPathPart         = environment.ticketsPathPart;
    private settlementsPathPart     = environment.settlementsPathPart;
    private repairsPathPart         = environment.repairsPathPart;
    private partsPathPart           = environment.partsPathPart;
    private protocolsPathPart       = environment.protocolsPathPart;
    private commentsPathPart        = environment.commentsPathPart;
    private accountsPathPart        = environment.accountsPathPart;

    constructor(
        protected httpClient: HttpClient,
        protected ticketsService: TicketsService,
        protected partTypesService: PartTypesService
    ) { }

    set protocolsWindow(windowObjectReference: Window | null) {
        if(this.protocolsWindow != windowObjectReference) {
            this.windowObjectReference = windowObjectReference;
        }
    }

    get protocolsWindow(): Window | null {
        return this.windowObjectReference;
    }

    public hasOneOfTheStatuses(settlement: Settlement, settlementStatuses: SettlementStatus[]): boolean {
        // console.log(settlementStatuses.length, settlementStatuses.length == 0);

        return settlementStatuses.length == 0 || settlementStatuses.some(
            (settlementStatus) => {
                return settlementStatus == settlement.status;
            }
        )
    }

    public getTickets(params: any): Observable<Ticket[]> {

        params = Object.assign(
            params,
            {
                company: null,
                number: null,
                mpk: null,
                city: null,
                street: null,
                region: null,
                limit: null,
                status: null,
                repair_from: null,
                repair_to: null,
                column: "id",
                order: "ASC"
            }
        );
        
        return this.httpClient.get(
            `${this.apiURL}/${this.ticketsPathPart}`,
            {
                params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (ticketsResponse: any) => {
                    const tickets: Ticket[] = [];
                    ticketsResponse.data.forEach((ticket: Ticket) => {
                        tickets.push(
                            this.ticketsService.prepareTicket(ticket)
                        );
                    });

                    return tickets;
                }
            )
        ) as Observable<Ticket[]>;
    }

    public getAccounts(params: any): Observable<Account[]> {
         
        
        return this.httpClient.get(
            `${this.apiURL}/${this.accountsPathPart}`,
            {
                params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (accountsResponse: any) => {
                    const accounts: Account[] = [];
                    accountsResponse.data.forEach((account: Account) => {
                        accounts.push(
                            this.prepareAccount(account)
                        );
                    });
                    
                    return accounts;
                }
            )
        ) as Observable<Account[]>;
    }

    public getSettlements(params: any): Observable<Page<Settlement>> {

        params = Object.assign(
            params,
            {
                limit: 15
            }
        );

        if(!params.column) {
            params.column = "id",
            params.order = "ASC"
        }
        
        return this.httpClient.get(
            `${this.apiURL}/${this.settlementsPathPart}`,
            {
                params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (settlementsResponse: any) => {
                    const settlements: Settlement[] = [];
                    settlementsResponse.data.forEach((settlement: Settlement) => {
                        settlements.push(
                            this.prepareSettlement(settlement)
                        );
                    });

                    return {
                        data: settlements,
                        meta: settlementsResponse.meta
                    }
                }
            )
        ) as Observable<Page<Settlement>>;
    }

    public createSettlement(ticketId: Number): Observable<Settlement> {
        return this.httpClient.post(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.settlementsPathPart}`,
            {}
        ).pipe(
            map( 
                (settlementsResponse: any) => {
                    return this.prepareSettlement(settlementsResponse.data)
                }
            )
        ) as Observable<Settlement>;
    } 

    /**
     * ...
     * 
     * @param settlementId
     * @returns 
     */
    public getSettlement(ticketId: number, settlementId: number): Observable<Settlement> {
        return this.httpClient.get(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.settlementsPathPart}/${settlementId}`,
            //`${this.settlementsApiURL}/${repairTypeId}`,
            {}
        ).pipe(
            map( 
                (settlementsResponse: any) => {
                    return this.prepareSettlement(settlementsResponse.data)
                }
            )
        ) as Observable<Settlement>;
    }

    private mapNullProperties(paramsWithNull: any) {
        const paramsWithoutNull = {...paramsWithNull};

        Object.keys(paramsWithoutNull).forEach(key => {
            if (paramsWithoutNull[key] === null || paramsWithoutNull[key] === -1 || paramsWithoutNull[key] === "-1") {
                paramsWithoutNull[key] = '';
            }
        });

        return paramsWithoutNull;
    }

    public prepareSettlement(settlement: any): Settlement {
        return {
            id: Number(settlement.id),
            status: settlement.status, // SettlementStatus
            ticket: this.ticketsService.prepareTicket(settlement.ticket),
            protocol_id: settlement.protocol ? Number(settlement.protocol.id) : -1,
            protocol: settlement.protocol ? this.ticketsService.prepareProtocol(settlement.protocol, settlement.protocol.settlement_ids) : null,
            company: settlement.company ? this.prepareCompany(settlement.company) : null,
            distance: settlement.distance ? Number(settlement.distance) : Number(settlement.ticket.shop.distance),
            distance_total: settlement.distance_total,
            repairs: this.prepareRepairs(settlement.repairs),
            comments: this.prepareComments(settlement.comments),
            total: Number(settlement.total),
            real_repair_date: String(settlement.real_repair_date)
        }
    }

    public prepareCompany(companyData: Company): Company {
        return {
            id: Number(companyData.id),
            name: companyData.name,
            role: companyData.role,
            distance_cost: Number(companyData.distance_cost),
            nocturnal_distance_coefficient: Number(companyData.nocturnal_distance_coefficient),
            nocturnal_default_price_coefficient: Number(companyData.nocturnal_default_price_coefficient)
        }
    }

    public prepareAccount(accountData: Account): Account {
        return {
            id: Number(accountData.id),
            number: accountData.number,
            description: accountData.description
        }
    }

    public prepareRepairs(repairsData: Repair[] | null): Repair[] {
        const repairs: Repair[] = [];

        if(repairsData) repairsData.forEach((repair: any) => {
            const is_nocturnal: boolean = repair.is_nocturnal != "0";

            repairs.push(
                {
                    id: Number(repair.id),
                    settlement_id: Number(repair.settlement_id),
                    quantity: Number(repair.quantity),
                    price: Number(repair.price),
                    total: Number(repair.total),
                    is_nocturnal: Boolean(is_nocturnal),
                    repair_type: this.prepareRepairType(repair.repair_type),
                    parts: this.prepareParts(repair.parts),
                    account: repair.account ? this.prepareAccount(repair.account) : null,
                    tariff_id: Number(repair.tariff_id)
                }
            );

            
        });

        return repairs;
    }

    public prepareRepair(repair: Repair): Repair {
        return {
            id: Number(repair.id),
            settlement_id: Number(repair.settlement_id),
            quantity: Number(repair.quantity),
            price: Number(repair.price),
            total: Number(repair.total),
            is_nocturnal: false,
            repair_type: this.prepareRepairType(repair.repair_type),
            parts: this.prepareParts(repair.parts),
            account: repair.account ? this.prepareAccount(repair.account) : null,
            tariff_id: Number(repair.tariff_id)
        }
    }

    public prepareComments(commentsData: Comment[] | null): Comment[] {
        const comments: Comment[] = [];

        if(commentsData) commentsData.forEach((commentData: Comment) => {
            const comment: Comment = this.prepareComment(commentData);

            comments.push(comment);

            
        });

        return comments;
    }

    public prepareComment(commentData: Comment): Comment {
        const comment: Comment = {
            id: Number(commentData.id),
            user: {
                id: Number(commentData.user.id),
                name: commentData.user.name,
                firstname: commentData.user.firstname,
                lastname: commentData.user.lastname,
                company: {
                    id: Number(commentData.user.company.id)
                },
            },
            created_at: commentData.created_at,
            updated_at: commentData.updated_at,
            settlement_id: commentData.settlement_id,
            refers_to_type: commentData.refers_to_type,
            refers_to_id: Number(commentData.refers_to_id),
            is_removed: Boolean(commentData.is_removed),
            text: commentData.text,
            group: null
        }

        switch(commentData.refers_to_type) {
            case "repair":
                comment.group = "repair_" + commentData.refers_to_id;
                break;
            case "part":
                comment.group = "part_" + commentData.refers_to_id;
                break;       
            case "distance":
                comment.group = "distance";
                break;
            default:
                comment.group = null;
        }

        return comment;
    }   

    public prepareParts(partsData: Part[] | null): Part[] {
        const parts: Part[] = [];

        if(partsData) partsData.forEach((part: Part) => {
            parts.push(
                this.preparePart(part)
            ); 
        });

        return parts;
    }

    public prepareRepairType(repairType: RepairTypeA): RepairTypeA {
        return {
            id: Number(repairType.id),
            name: repairType.name,
            company_id: Number(repairType.company_id),
            group_id: Number(repairType.group_id),
            group_name: repairType.group_name,
            default_price: Number(repairType.default_price),
            default_quantity: Number(repairType.default_quantity),
            
            repair_category: {
                id: 1,
                key: "test",
                name: "test"
            },
            key: "test"
        }
    }

    public preparePart(part: Part): Part {
        // part.part_type_id = part.part_type.suppliers[0].id;

        let supplier: Supplier | undefined = part.part_type.suppliers.find(
            (item: Supplier) => Number(item.id) == Number(part.part_type_id)
        );    

        const available_from_frio: boolean = (part.part_type.suppliers.findIndex(
            (item: Supplier) => Number(item.company_id) == 1
        ) > -1);

        const available_from_installer: boolean = (part.part_type.suppliers.findIndex(
            (item: Supplier) => Number(item.company_id) == 1
        ) > -1);

        if(!supplier) throw new Error('Nie znaleziono wskazanego typu części, w definicji części');

        return {
            id: Number(part.id),
            repair_id: Number(part.repair_id),
            part_type_id: Number(part.part_type_id),
            available_from_frio: available_from_frio,
            available_from_installer: available_from_installer,
            supplier: supplier,
            part_type: this.partTypesService.preparePartType(part.part_type),
            quantity: Number(part.quantity),
            price: Number(part.price),
            total: Number(part.total)
        }
    }

    public createRepair(settlementId: Number, repairTypeId: Number): Observable<{
        repair: Repair
        settlement_total: number
    }> {
        return this.httpClient.post(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}`,
            {
                repair_type_id: repairTypeId
            }
        ).pipe(
            map( 
                (repairResponse: any) => {
                    const is_nocturnal: boolean = false;

                    return {
                        repair: {
                            id: Number(repairResponse.repair.id),
                            settlement_id: Number(repairResponse.repair.settlement_id),
                            quantity: Number(repairResponse.repair.quantity),
                            price: Number(repairResponse.repair.price),
                            total: Number(repairResponse.repair.total),
                            is_nocturnal: is_nocturnal,
                            repair_type: this.prepareRepairType(repairResponse.repair.repair_type),
                            parts: [],
                            account: repairResponse.repair.account ? this.prepareAccount(repairResponse.repair.account) : null,
                            tariff_id: Number(repairResponse.repair.tariff_id)
                        },
                        settlement_total: repairResponse.settlement_total
                    }
                }
            )
        ) as Observable<{
            repair: Repair
            settlement_total: number
        }>;
    }

    public updateSettlement(ticketId: number, settlementId: Number, params:any): Observable<Settlement> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.settlementsPathPart}/${settlementId}`,
            params
        ).pipe(
            map( 
                (settlementsResponse: any) => {
                    return this.prepareSettlement(settlementsResponse.data)
                }
            )
        ) as Observable<Settlement>;
    }

    public updateRepair(
        settlementId: Number, 
        repairId: Number, 
        params: {
            quantity: number;
            tariff_id: number;
            account_id?: number;
        }
    ): Observable<{
        repair: Repair
        settlement_total: number
    }> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}`,
            params
        ).pipe(
            map( 
                (repairResponse: any) => {
                    return {
                        repair: this.prepareRepair(repairResponse.repair),
                        settlement_total: repairResponse.settlement_total
                    }
                }
            )
        ) as Observable<{
            repair: Repair
            settlement_total: number
        }>;
    }

    public deleteRepair(settlementId: Number, repairId: Number): Observable<{ settlement_total: number }> {
        return this.httpClient.delete(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}`
        ).pipe(
            map(
                (res: any) => {
                    return { settlement_total: Number(res.settlement_total) }
                }
            ) 
        ) as Observable<{ settlement_total: number }>;
    }

    public deleteSettlement(ticketId: number, settlementId: Number): Observable<boolean> {
        return this.httpClient.delete(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.settlementsPathPart}/${settlementId}`
        ).pipe(
            map(
                (res: any) => {
                    return res
                }
            ) 
        ) as Observable<boolean>;
    }

    public createPart(settlementId: Number, repairId: Number, partTypeId: number, quantity: Number): Observable<{
        part: Part
        settlement_total: number
    }> {
        return this.httpClient.post(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}/${this.partsPathPart}`,
            {
                part_type_id: partTypeId,
                quantity: quantity
            }
        ).pipe(
            map( 
                (partResponse: any) => {
                    return {
                        part: this.preparePart(partResponse.part),
                        settlement_total: partResponse.settlement_total
                    }
                }
            )
        ) as Observable<{
            part: Part
            settlement_total: number
        }>;
    }

    public updatePart(settlementId: Number, repairId: Number, partId: number, change: any): Observable<{
        part: Part
        settlement_total: number
    }> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}/${this.partsPathPart}/${partId}`,
            change
        ).pipe(
            map( 
                (partResponse: any) => {
                    return {
                        part: this.preparePart(partResponse.part),
                        settlement_total: partResponse.settlement_total
                    }
                }
            )
        ) as Observable<{
            part: Part
            settlement_total: number
        }>;
    }

    public deletePart(settlementId: Number, repairId: Number, partId: Number): Observable<{ settlement_total: number }> {
        return this.httpClient.delete(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}/${this.partsPathPart}/${partId}`
        ).pipe(
            map(
                (res: any) => {
                    return { settlement_total: Number(res.settlement_total) }
                }
            ) 
        ) as Observable<{ settlement_total: number }>;
    }

    /**
     * ...
     * 
     * @param settlementId
     * @returns 
     */
    public getProtocols(ticketId: number): Observable<Protocol[]> {
        return this.httpClient.get(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.protocolsPathPart}`,
            {}
        ).pipe(
            map( 
                (protocolsResponse: any) => {
                    return this.ticketsService.prepareProtocols(protocolsResponse.data, protocolsResponse.data.settlement_ids)
                }
            )
        ) as Observable<Protocol[]>;
    }

    public createComment(settlementId: Number, params: any): Observable<Comment> {
        return this.httpClient.post(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.commentsPathPart}`,
            params
        ).pipe(
            map( 
                (partResponse: any) => {
                    return this.prepareComment(partResponse.data);
                }
            )
        ) as Observable<Comment>;
    }

    getSettlementHistory(settlementId: number): Observable<SettlementHistory[]> {
        return this.httpClient.get(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/historical`,
            {}
        ).pipe(
            map( 
                (settlementHistoryResponse: any) => {
                    const settlementHistories: SettlementHistory[] = [];

                    settlementHistoryResponse.data.forEach(
                        (item: SettlementHistory) => {
                            settlementHistories.push(this.prepareTicketHistory(item));
                        }
                    )
                    
                    return settlementHistories;
                }
            )
        ) as Observable<any>;
    }

    prepareTicketHistory(settlementHistory: SettlementHistory): SettlementHistory {
        return {
            id: Number(settlementHistory.id),
            user: {
                name: settlementHistory.user.name,
                firstname: settlementHistory.user.firstname,
                lastname: settlementHistory.user.lastname,
                company: {
                    name: settlementHistory.user.company.name
                }
            },
            old_status: settlementHistory.old_status,
            new_status: settlementHistory.new_status,
            description: settlementHistory.description,
            created_at: settlementHistory.created_at
        }
    } 
}
