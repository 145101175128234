import { Component } from '@angular/core';
import { PartTypesService } from '../../common/services/parts/part-types.service';
import { PartType } from '../../common/models/part_type';
import { Observable } from 'rxjs';
import { RepairTypesService } from '../../common/services/repairs/repair-types.service';
import { RepairTypeA } from '../../common/models/repair-type-a';
import { Settlement } from '../../common/models/settlement';
import { SettlementsService } from '../../common/services/settlements/settlements.service';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent {

    zoom: boolean = false;

    constructor(
        protected partTypesService: PartTypesService,
        protected repairTypesService: RepairTypesService,
        protected settlementsService: SettlementsService
    ) {

    }
    
    public onGetPartTypes() {
        this.partTypesService.getPartTypes({}).subscribe(
            {
                next: (partTypes: PartType[]) => {
                    console.log(partTypes);
                },
                error: (error: any) => {
                    console.error(error);
                },
                complete: () => {
                }
            }
        );
    }

    public onGetPartType() {
        this.partTypesService.getPartType(1).subscribe(
            {
                next: (partType: PartType) => {
                    console.log(partType);
                },
                error: (error: any) => {
                    console.error(error);
                },
                complete: () => {
                }
            }
        );
    }

    public onGetRepairTypes() {
        this.repairTypesService.getRepairTypes({}).subscribe(
            {
                next: (repairTypes: RepairTypeA[]) => {
                    console.log(repairTypes);
                },
                error: (error: any) => {
                    console.error(error);
                },
                complete: () => {
                }
            }
        );
    }

    public onGetRepairType() {
        this.repairTypesService.getRepairType(1).subscribe(
            {
                next: (repairType: RepairTypeA) => {
                    console.log(repairType);
                },
                error: (error: any) => {
                    console.error(error);
                },
                complete: () => {
                }
            }
        );
    }

    public onGetSettlements() {
        this.settlementsService.getSettlements({}).subscribe(
            {
                next: (settlements: any) => {
                    console.log(settlements);
                },
                error: (error: any) => {
                    console.error(error);
                },
                complete: () => {
                }
            }
        );
    }

    public onGetSettlement() {
        this.settlementsService.getSettlement(1,1).subscribe(
            {
                next: (settlement: Settlement) => {
                    console.log(settlement);
                },
                error: (error: any) => {
                    console.error(error);
                },
                complete: () => {
                }
            }
        );
    }

    public onZoom(zoom: boolean) {
        this.zoom = zoom;
    }
}
