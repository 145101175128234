import { Component, ElementRef, HostListener, Inject, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-file-upload',
    templateUrl: 'file-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true
        }
    ]
})
export class FileUploadComponent implements ControlValueAccessor {

    onChange!: Function;
    file: File | null = null;

    @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
        const file = event && event.item(0);
        this.onChange(file);
        this.file = file;
    }

    constructor( private host: ElementRef<HTMLInputElement> ) {
    }

    writeValue( value: null ) {
        // clear file input
        this.host.nativeElement.value = '';
        this.file = null;
    }

    registerOnChange( fn: Function ) {
        this.onChange = fn;
    }

    registerOnTouched( fn: Function ) {
    
    }
    
}