import { Injectable } from '@angular/core';
import { Observable, delay, map, of } from 'rxjs';
import { PartType } from '../../models/part_type';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { Page } from '../../models/lists/page';

@Injectable({
    providedIn: 'root'
})
export class PartTypesService {

    private partTypes: PartType[] = [
       
    ];

    private partTypesApiURL = environment.partTypesApiURL;

    constructor(protected httpClient: HttpClient) { }

    getParts(): Observable<PartType[]> {
        return of(this.partTypes);
    }

    public getPartTypes(params: any): Observable<PartType[]> {
        return this.httpClient.get(
            `${this.partTypesApiURL}`,
            {
                //params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (partTypesWrapper: any) => {
                    const partTypes: PartType[] = [];
                    partTypesWrapper.forEach((partTypeItem: PartType) => {
                        partTypes.push(this.preparePartType(partTypeItem));
                    });

                    return partTypes;
                }
            )
        ) as Observable<PartType[]>;
    }


    public getPaginatedPartTypes(params: any): Observable<Page<PartType>> {

        params = Object.assign(
            params,
            {}
        );

        if(!params.column) {
            params.column = "id",
            params.order = "ASC"
        }
        
        return this.httpClient.get(
            `${this.partTypesApiURL}`,
            {
                params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (partTypesResponse: any) => {
                    const partTypes: PartType[] = [];
                    partTypesResponse.data.forEach((partTypeItem: PartType) => {
                        partTypes.push(this.preparePartType(partTypeItem));
                    });

                    return {
                        data: partTypes,
                        meta: partTypesResponse.meta 
                            ? partTypesResponse.meta
                            : {
                                current_page: 1,
                                from: 1,
                                last_page: 1,
                                per_page: partTypes.length,
                                to: partTypes.length,
                                total: partTypes.length
                            }
                    }
                }
            )
        ) as Observable<Page<PartType>>;
    }

    /**
     * ...
     * 
     * @param partTypeId
     * @returns 
     */
    public getPartType(partTypeId: number): Observable<PartType> {
        return this.httpClient.get(
            `${this.partTypesApiURL}/${partTypeId}`,
            {}
        ).pipe(
            map( 
                (partTypeDTO: any) => {
                    return this.preparePartType(partTypeDTO[0])
                }
            )
        ) as Observable<PartType>;
    }

    public preparePartType(partTypeWrapper: PartType): PartType {
        const partType: PartType = {
            id: partTypeWrapper.id,
            name: partTypeWrapper.name,
            suppliers: [],
            default_price: 1,
            default_quantity: 1
        }

        if(partTypeWrapper.suppliers) {

            partTypeWrapper.suppliers.forEach(
                (supplierItem: any) => {
                    const supplier = {
                        id: supplierItem.id,
                        company_id: supplierItem.company_id,
                        default_price: Number(supplierItem.default_price),
                        default_quantity: Number(supplierItem.default_quantity),
                        is_active: supplierItem.is_active == "1"
                    };

                    partType.suppliers.push(supplier); 
                }
            );

        }
        return partType;
    }

    public createPartType(params: any): Observable<PartType> {
        return this.httpClient.post(
            `${this.partTypesApiURL}`,
            {}
        ).pipe(
            map( 
                (partTypeDTO: any) => {
                    return this.preparePartType(partTypeDTO[0])
                }
            )
        ) as Observable<PartType>;
    }

   

    public updatePartTypeName(partGroupId: number, name: string): Observable<boolean> {
        return this.httpClient.patch(
            `${this.partTypesApiURL}/${partGroupId}`,
            {
                name: name
            }
        ).pipe(
            map( 
                (partTypeDTO: any) => {
                    return true;
                }
            )
        ) as Observable<boolean>;
    }

    public updatePartTypeAvailability(partGroupId: number, partTypeId: number, available: boolean): Observable<boolean> {
        return this.httpClient.patch(
            `${this.partTypesApiURL}/${partGroupId}/${partTypeId}`,
            {
                is_active: available
            }
        ).pipe(
            map( 
                (partTypeDTO: any) => {
                    return true;
                }
            )
        ) as Observable<boolean>;
    }

    public updatePartTypePrice(partGroupId: number, partTypeId: number, price: string): Observable<boolean> {
        return this.httpClient.patch(
            `${this.partTypesApiURL}/${partGroupId}/${partTypeId}`,
            {
                default_price: String(price).replace(",", ".")
            }
        ).pipe(
            map( 
                (partTypeDTO: any) => {
                    return true;
                }
            )
        ) as Observable<boolean>;
    }

    public updatePartTypeQuantity(partGroupId: number, partTypeId: number, quantity: string): Observable<boolean> {
        return this.httpClient.patch(
            `${this.partTypesApiURL}/${partGroupId}/${partTypeId}`,
            {
                default_quantity:  String(quantity).replace(",", ".")
            }
        ).pipe(
            map( 
                (partTypeDTO: any) => {
                    return true;
                }
            )
        ) as Observable<boolean>;
    }

    private mapNullProperties(paramsWithNull: any) {
        const paramsWithoutNull = {...paramsWithNull};

        Object.keys(paramsWithoutNull).forEach(key => {
            if (paramsWithoutNull[key] === null || paramsWithoutNull[key] === -1 || paramsWithoutNull[key] === "-1") {
                paramsWithoutNull[key] = '';
            }
        });

        return paramsWithoutNull;
    }
}
