
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { User } from '../models/user';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { Group } from '../../../common/models/group';
import { CountsService } from '../../../common/services/counts.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private apiURL                  = environment.apiURL;
    private usersPathPart           = environment.usersPathPart;
    protected groupSubject$!: BehaviorSubject<Group | null>;
    public group$!: Observable<Group | null>;

    constructor(
        protected http: HttpClient,
        protected authService: AuthService,
        public countsService: CountsService
    ) {
        let group: Group | null = null;

        this.groupSubject$ = new BehaviorSubject<Group | null>(group);
        this.group$ = this.groupSubject$.asObservable();
    }

    public setActiveGroup(group: Group): Observable<any> {
        const user: User | null = this.authService.user;

        if(!user) {
            return of(false);
        }

        return this.http.patch(
            `${this.apiURL}/${this.usersPathPart}/${user.id}/named_shop_groups/${group.id}`, 
            {}
        ).pipe(
            tap(
                () => {
                    if(this.authService.user) {
                       // this.authService.user.namedShopGroup = group;
                       this.groupSubject$.next(group);
                       this.authService.setContext(group);
                       this.countsService.refreshSettlementsCount();
                    }
                }
            ) 
        )
    }
}
