<div class="flex fixed inset-0" style="left: 280px;" >
    <div class="flex w-full h-full">
        <div [ngClass]="{'w-4/12': !zoom, 'w-6/12': zoom}" class="grow-1 h-full flex flex-col justify-between" style="transition-property: width;
        transition-duration: 0.5s;">
            <div class="app-image-viewer h-full" [thumbnails]="false" (zoom)="onZoom($event)"></div>
        </div>
        <div class="w-20 grow border-l p-4">
            Druga kolumna
        </div>
    </div>
</div>