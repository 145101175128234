<h1 mat-dialog-title>
    <span class="text-red-600">Błąd danych (Błąd komunikacji z API)</span>
</h1>
<div mat-dialog-content>
    <div class="text-lg mr-8">{{message}}</div>
    <div class="mt-4" *ngIf="errors">
        <ul class="list-disc list-inside">
            <li class="" *ngFor="let error of errors; index as i;">{{error.key}}: {{error.error}}</li>
        </ul>
    </div>
</div>
<div mat-dialog-actions>
    <div class="p-4 flex justify-end w-full">
        <button  mat-flat-button color="primary" mat-dialog-close>Zamknij</button>
    </div>
</div>