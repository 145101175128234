import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { Protocol } from '../../common/models/protocol';
import { P } from '@angular/cdk/keycodes';
import { SettlementsService } from '../../common/services/settlements/settlements.service';

@Component({
  selector: '.app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, AfterViewInit {

    private clientURL: string                 = environment.clientURL;
    private settlementsClientPathPart         = environment.settlementsClientPathPart;
    private protocolsClientPathPart: string   = environment.protocolsClientPathPart;

    // @HostListener("wheel", ["$event"])
    // public onScroll(event: WheelEvent) {
    //     if(event.deltaY > 0) {
    //         this.zoomOut();
    //     } else {
    //         this.zoomIn();
    //     }
    // }

    @Output()
    selectedProtocolId = new EventEmitter<number>(); 

    @Output()
    zoom = new EventEmitter<boolean>();
    zoomed: boolean = false;

    @Input()
    settlementId!: number;

    @Input()
    ticketId!: number;

    @Input()
    images: Protocol[] = [];

    @Input()
    resize: boolean = true;

    @Input()
    fullScreen: boolean = true;

    @Input()
    thumbnails: boolean = true;

    @Input()
    menu: boolean = true;

    @Input()
    selectedImageId: number = -1;

    @Input()
    previewOnly: boolean = false;

    @Input()
    chooseProtocolButton: boolean = false;

    @Input()
    mode: "horizontal" | "vertical" = "horizontal";

    windowObjectReference: Window | null = null;

    constructor(
        private settlementService: SettlementsService
    ) {}

    viewer: any;
    imageSources: Protocol[] = [];
    
    currentIndex = -1;
    

    @ViewChild('myCanvas')
    canvas!: ElementRef<HTMLCanvasElement>;

    @ViewChild('myCanvasContainer')
    canvasContainer!: ElementRef<HTMLElement>;

    @ViewChild('info') 
    info!: ElementRef<HTMLElement>;
    
    context!: CanvasRenderingContext2D | null;


    currentX: number = 0;
    currentY: number = 0;
    image: HTMLImageElement = new Image();
    isDraggable: boolean = false;
    currentScale: number = 1.25;
    deltaX: number = 0;
    deltaY: number = 0;
    imageIndex: number = -1;

    canvasContainerResizeObserver!: ResizeObserver; 

    loading: boolean = true;


    ngOnChanges(changes: SimpleChanges) {
        if(changes['images']) {
            if(JSON.stringify(changes['images'].currentValue) != JSON.stringify(changes['images'].previousValue)) {
                this.imageSources = changes['images'].currentValue;
                if(this.imageSources.length > 0 && this.selectedImageId != -1) {
                    this.updateView();   
                }
            }
        } else if(changes['selectedImageId']) {
            if(changes['selectedImageId'].currentValue != changes['selectedImageId'].previousValue) {
                if(this.imageSources.length > 0 && this.selectedImageId != -1) {
                    this.updateView();   
                }
            }
        }
    }

    updateView() {
        const imageIndex: number = this.imageSources.findIndex(
            (item) => item.id == this.selectedImageId
        )

        if(imageIndex > -1) this.selectImage(imageIndex);
    }

    ngAfterViewInit() {
        //this.resizeCanvas();
        this.context = this.canvas.nativeElement.getContext('2d');

        if(this.image && this.context) {
            this.image.addEventListener("load", (e: any) => {
                this.loading = false;
                this.resetScale();
                this.drawImage();
            });
        }

        this.canvas.nativeElement.addEventListener('wheel', e => {
            if(e.deltaY > 0) {
                this.zoomOut();
            } else {
                this.zoomIn();
            }
        })

        this.canvas.nativeElement.addEventListener('mousedown', e => {
           
            const mouseX = e.pageX - this.canvas.nativeElement.offsetLeft;
            const mouseY = e.pageY - this.canvas.nativeElement.offsetTop;
          
            if (this.inRect(e)) {
                this.isDraggable = true;
            }

            this.deltaX = mouseX - this.currentX;
            this.deltaY = mouseY - this.currentY;
        });

        this.canvas.nativeElement.addEventListener('mousemove', e => {
            if (this.isDraggable) {
                this.currentX = e.pageX - this.canvas.nativeElement.offsetLeft - this.deltaX;
                this.currentY = e.pageY - this.canvas.nativeElement.offsetTop - this.deltaY;
                this.resetCanvas();
                this.drawImage();
            }

            if (this.inRect(e)) {
                this.canvas.nativeElement.classList.add('cursor-pointer');
            } else {
                this.canvas.nativeElement.classList.remove('cursor-pointer');
            }
        });

        this.canvas.nativeElement.addEventListener('mouseup', e => {
            this.isDraggable = false;
        });

        this.canvas.nativeElement.addEventListener('mouseout', e => {
            this.isDraggable = false;
        });

        window.addEventListener('resize', () => {
            this.resizeCanvas();
        })

        this.canvasContainerResizeObserver = new ResizeObserver(
            e => {
                this.resizeCanvas();
            }
        );
        this.canvasContainerResizeObserver.observe(this.canvasContainer.nativeElement);
    }

    inRect(e: any) {
        const viewportOffset = this.canvasContainer.nativeElement.getBoundingClientRect();

        const mouseX = e.pageX - this.canvasContainer.nativeElement.offsetLeft - viewportOffset.left;
        const mouseY = e.pageY - this.canvasContainer.nativeElement.offsetTop;

        if (mouseX >= (this.currentX - this.image.naturalWidth * this.currentScale / 2) &&
            mouseX <= (this.currentX + this.image.naturalWidth * this.currentScale / 2) &&
            mouseY >= (this.currentY - this.image.naturalHeight * this.currentScale / 2) &&
            mouseY <= (this.currentY + this.image.naturalHeight * this.currentScale / 2)) return true;
    
        return false;
    }

    zoomIn() {
        if(this.context) {
            this.currentScale = this.currentScale + 0.1;
            //this.context.scale(this.currentScale, this.currentScale);
            //this.currentX = this.canvas.nativeElement.width / (2 * this.currentScale);
            //this.currentY = this.canvas.nativeElement.height / (2 * this.currentScale);
            this.resetCanvas();

            this.drawImage();
        }
    }

    zoomOut() {
        if(this.context) {
            this.currentScale = this.currentScale > 0.19 ? this.currentScale - 0.1 : 0.1;
            //this.context.scale(this.currentScale, this.currentScale);
            //this.currentX = this.canvas.nativeElement.width / (2 * this.currentScale);
            //this.currentY = this.canvas.nativeElement.height / (2 * this.currentScale);
            this.resetCanvas();
            this.drawImage();
        }
    }

    resetView() {
        this.resetScale();
        this.resetCenter(); 
        this.resetCanvas();
        this.drawImage()
    }

    resetScale() {
        let scaleX = 1;
        let scaleY = 1;

        if(this.canvas.nativeElement.width < this.image.naturalWidth) {
            scaleX = this.canvas.nativeElement.width/this.image.naturalWidth;
        }

        if(this.canvas.nativeElement.height < this.image.naturalHeight) {
            scaleY = this.canvas.nativeElement.height/this.image.naturalHeight;    
        }

        if(scaleX < 1 || scaleY < 1) {
            this.currentScale = scaleX < scaleY ? scaleX : scaleY;
        }
    }

    resetCenter() {
        this.currentX =  this.canvas.nativeElement.width / 2;
        this.currentY =  this.canvas.nativeElement.height / 2; 
    }


    showInNewWindow() {
        
    }

    previousWidth: number = -1;
    previousHeight: number = -1;
        
    resizeCanvas() {
        if(this.previousWidth > 0 && this.previousHeight > 0) {
            const nextScaleW: number = (this.canvasContainer.nativeElement.offsetWidth / this.previousWidth) * this.currentScale; 
            const nextScaleH: number = (this.canvasContainer.nativeElement.offsetHeight / this.previousHeight) * this.currentScale;

            this.currentScale = nextScaleW;
        }

        this.previousWidth = this.canvasContainer.nativeElement.offsetWidth;
        this.previousHeight = this.canvasContainer.nativeElement.offsetHeight;

        this.canvas.nativeElement.width = this.canvasContainer.nativeElement.offsetWidth;
        this.canvas.nativeElement.height = this.canvasContainer.nativeElement.offsetHeight;

        if(this.info) this.info.nativeElement.setAttribute('style', `width: ${this.canvas.nativeElement.width}px; height: ${this.canvas.nativeElement.height}px`);

        this.resetCenter();    
                    
        this.resetCanvas();

        this.drawImage(); 
    }

    drawImage() {
        if(this.context) {
            this.context.clearRect(0, 0, 30000, 30000);
            this.context.drawImage(
                this.image, 
                0,
                0,
                this.image.naturalWidth,
                this.image.naturalHeight,
                this.currentX - (this.image.naturalWidth  * this.currentScale) / 2,
                this.currentY - (this.image.naturalHeight  * this.currentScale) / 2,
                //this.currentX - (this.image.naturalWidth) / 2, 
                //this.currentY - (this.image.naturalHeight) / 2, 
                this.image.naturalWidth * this.currentScale, 
                this.image.naturalHeight * this.currentScale
            ); 
        }
    }

    resetCanvas() {
        if(!this.context) return;
        // this.context.fillStyle = '#fff';
        // this.context.fillRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.context.clearRect(0, 0, 30000, 30000);
    }

    ngOnInit() {
       
    }

    selectImage(index: number) {
        if(index == -1 || this.image.src == this.imageSources[index].path) return;

        this.imageIndex = index;
        this.loading = true;

        this.resetCanvas();
        this.image.src = this.imageSources[this.imageIndex].path;
    }

    expandCollapse() {
        this.zoomed = !this.zoomed;
        this.zoom.emit(this.zoomed);
    }

    selectProtocol(imageSourceId: number) {
        this.selectedProtocolId.emit(imageSourceId);
    }

    fullScreenMode() {
        this.openRequestedTab(`${this.clientURL}/${this.settlementsClientPathPart}/${this.settlementId}/${this.protocolsClientPathPart}?ticket_id=${this.ticketId}`, "protocols");
    }

    openRequestedTab(url: string, windowName: string) {
        if (this.settlementService.protocolsWindow === null || this.settlementService.protocolsWindow.closed) {
            this.settlementService.protocolsWindow = window.open(url, "_blank", 'toolbar=0,location=0,menubar=0');
        } else {
            this.settlementService.protocolsWindow.focus();
        }
    }

    getNavClasses() {
        return this.mode == "horizontal" 
            ? "border-t overflow-x-auto overflow-y-hidden h-20"
            : "border-l overflow-x-hidden overflow-y-auto w-20";
    }

    onScrollx() {
        
    }

    @Output()
    chooseProtocolEvent = new EventEmitter<number>(); 

    chooseProtocol() {
        const value = Math.random()
        this.chooseProtocolEvent.emit(value);
    }

    protocolLoaded(protocol: Protocol) {
        protocol.loaded = true;
    }
}

