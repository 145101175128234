<h1 mat-dialog-title class="dialog-header">Wybierz kontekst</h1>
<form *ngIf="mpkGroupForm" [formGroup]="mpkGroupForm" (ngSubmit)="onSubmit()" class="example-form">
    <div mat-dialog-content>
        <div class="flex">
            <div class="w-full">
                <div class="mb-2">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Kontekst:</mat-label>
                        <mat-select formControlName="mpk_group">
                            <mat-option *ngFor="let group of groups" value="{{group.id}}">{{group.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        
        
    </div>
    <div mat-dialog-actions align="end" class="p-4">
        <button 
            type="button"
            (click)="onNoClick()"
            mat-button 
            class="button-large w-24"
        >
            Anuluj
        </button>
        <button 
            mat-flat-button 
            color="primary" 
            class="button-large w-24" 
            cdkFocusInitial
        >
            Wybierz
        </button>
    </div>
</form>
