import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { environment } from "projects/frioarte-service-panel/src/environments/environment";
import { BehaviorSubject, Observable, map } from "rxjs";
import { SettlementsToReact } from "../models/counts/settlements-to-react";
import { AuthService } from "../../modules/auth/services/auth.service";
import { RoleKey } from "../../modules/auth/models/role-key";
import { ShopCount } from "../models/counts/shop_count";

@Injectable({
    providedIn: 'root'
})
export class CountsService {
    

    windowObjectReference: Window | null = null;

    private apiURL                  = environment.apiURL;
    private countsPathPart          = environment.countsPathPart;

    protected settlementsToReactSubject$!: BehaviorSubject<SettlementsToReact | null>;
    public settlementsToReact$!: Observable<SettlementsToReact | null>;
    
    protected shopsWithoutDistancesSubject$!: BehaviorSubject<ShopCount | null>;
    public shopsWithoutDistances$!: Observable<ShopCount | null>;

    protected shopsWithoutContextsSubject$!: BehaviorSubject<ShopCount | null>;
    public shopsWithoutContexts$!: Observable<ShopCount | null>;
    
    protected shopsWithoutGuaranteeDatesSubject$!: BehaviorSubject<ShopCount | null>;
    public shopsWithoutGuaranteeDates$!: Observable<ShopCount | null>;

    constructor(
        protected httpClient: HttpClient,
        protected authService: AuthService,
    ) { 
        this.settlementsToReactSubject$ = new BehaviorSubject<SettlementsToReact | null>(null);
        this.settlementsToReact$ = this.settlementsToReactSubject$.asObservable();

        this.shopsWithoutDistancesSubject$ = new BehaviorSubject<ShopCount | null>(null);
        this.shopsWithoutDistances$ = this.shopsWithoutDistancesSubject$.asObservable();

        this.shopsWithoutContextsSubject$ = new BehaviorSubject<ShopCount | null>(null);
        this.shopsWithoutContexts$ = this.shopsWithoutContextsSubject$.asObservable();

        this.shopsWithoutGuaranteeDatesSubject$ = new BehaviorSubject<ShopCount | null>(null);
        this.shopsWithoutGuaranteeDates$ = this.shopsWithoutGuaranteeDatesSubject$.asObservable();

        this.onInit();
    }

    settlementsToReactTimer: any = null;
    
    onInit(): void {
        if(!this.settlementsToReactTimer) {
            this.settlementsToReactTimer = setInterval(
                () => {                           
                    this.refresh();
                }, 
                60000
            );

            this.authService.user$.subscribe(
                () => {
                    this.refresh();
                }
            )
        }
    }

    refresh() {
        if(this.authService.user && this.authService.user.roles[0] != RoleKey.accounting) {
            this.getSettlementsToReact().subscribe(
                {
                    next: (res: SettlementsToReact) => {
                        this.settlementsToReactSubject$.next(res);
                    },
                    error: () => {
                        this.settlementsToReactSubject$.next(null);
                    }
                }
            )

            this.getShopsWithoutDistances().subscribe(
                {
                    next: (res: ShopCount) => {
                        this.shopsWithoutDistancesSubject$.next(res);
                    },
                    error: () => {
                        this.shopsWithoutDistancesSubject$.next(null);
                    }
                }
            )

            this.getShopsWithoutContexts().subscribe(
                {
                    next: (res: ShopCount) => {
                        this.shopsWithoutContextsSubject$.next(res);
                    },
                    error: () => {
                        this.shopsWithoutContextsSubject$.next(null);
                    }
                }
            )

            if(this.authService.performsOneOfTheRoles([RoleKey.operator, RoleKey.admin, RoleKey.super_admin])) {
                this.getShopsWithoutGuaranteeDates().subscribe(
                    {
                        next: (res: ShopCount) => {
                            this.shopsWithoutGuaranteeDatesSubject$.next(res);
                        },
                        error: () => {
                            this.shopsWithoutGuaranteeDatesSubject$.next(null);
                        }
                    }
                )
            }
        }
    }

    refreshSettlementsCount() {
        this.refresh();
    }

    public getSettlementsToReact(): Observable<SettlementsToReact> {
        return this.httpClient.get(
            `${this.apiURL}/${this.countsPathPart}/settlements_to_react`,
            {}
        ) as Observable<SettlementsToReact>;
    }

    public getShopsWithoutDistances(): Observable<ShopCount> {
        return this.httpClient.get(
            `${this.apiURL}/${this.countsPathPart}/shops_without_distances`,
            {}
        ).pipe(
            map(
                (res: any) => {
                    return res.data;
                } 
            )
        ) as Observable<ShopCount>;
    }

    public getShopsWithoutContexts(): Observable<ShopCount> {
        return this.httpClient.get(
            `${this.apiURL}/${this.countsPathPart}/shops_without_contexts`,
            {}
        ).pipe(
            map(
                (res: any) => {
                    return res.data;
                } 
            )
        ) as Observable<ShopCount>;
    }

    public getShopsWithoutGuaranteeDates(): Observable<ShopCount> {
        return this.httpClient.get(
            `${this.apiURL}/${this.countsPathPart}/shops_without_guarantee_dates`,
            {}
        ).pipe(
            map(
                (res: any) => {
                    return res.data;
                } 
            )
        ) as Observable<ShopCount>;
    }
    
}

