import { Component } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GroupsService } from '../../common/services/groups/groups.service';
import { Group } from '../../common/models/group';
import { Page } from '../../common/models/lists/page';
import { UsersService } from '../../modules/auth/services/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-select-mpk-group-dialog',
    templateUrl: './select-mpk-group-dialog.component.html',
    styleUrls: ['./select-mpk-group-dialog.component.scss']
})
export class SelectMpkGroupDialogComponent {
    public mpkGroupForm!: FormGroup;
    public mpkGroupFC!: FormControl;
    public groups: Group[] = [];

    constructor(
        public dialogRef: MatDialogRef<SelectMpkGroupDialogComponent>,
        protected usersService: UsersService,
        protected groupsService: GroupsService,
        protected formBuilder: FormBuilder,
        private _snackBar: MatSnackBar,
    ) {
    }

    ngOnInit(): void {
        this.mpkGroupFC = new FormControl(null, [Validators.required]);
        this.groupsService.getGroups({
            name: "",
            author: "",
            column: "name",
            order: "ASC"
        }).subscribe(
            {
                next: (groupsData: Page<Group>) => {
                    groupsData.data.unshift({
                        id: 0,
                        name: "Brak",
                        mpks: [],
                        author: {
                            firstname: "",
                            lastname: ""
                        }
                    })
                    this.groups = groupsData.data;
                },
                error: () => {},
                complete: () => {}
            }
        )
        
        this.mpkGroupForm = this.formBuilder.group({
            mpk_group:       this.mpkGroupFC, 
        });
    }
    
    onSubmit() {
        if(this.mpkGroupForm.invalid) return;

        const group: Group | undefined = this.groups.find(
            (item: Group) => item.id == this.mpkGroupForm.controls['mpk_group'].value
        );

        if(!group) return;

        this.usersService.setActiveGroup(group).subscribe(
            {
                next: () => {
                   this.openSnackBar("Ustawiono wybrany kontekst.");
                   this.onNoClick();
                },
                error: () => {
                    this.onNoClick();
                },
                complete: () => {
                    this.onNoClick();
                }
            }
        )
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    openSnackBar(message: string, action: string | undefined = undefined) {
        this._snackBar.open(message, action, { duration: 3000, verticalPosition: "top" });
    }
}

