// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//const APP_URL =                   "http://127.0.0.1/frioarte/rozliczenia/public";
// const APP_URL =                     "https://dev.api.frioarte-serwis.pl";
// const CLIENT_URL =                  "http://dev.frioarte-serwis.pl";
// const SERVICE_PLATFORM_URL =        "https://ccfa.azurewebsites.net/Notification/Details?mark=";
// const SERVICE_PLATFORM_SHOPS_URL =  "https://ccfa.azurewebsites.net/Database/Shop/Edit";

const APP_URL =                      "https://test.api.frioarte-serwis.pl";
const CLIENT_URL =                   "http://test.frioarte-serwis.pl";
const SERVICE_PLATFORM_URL =         "https://ccfa.azurewebsites.net/Notification/Details?mark=";
const SERVICE_PLATFORM_SHOPS_URL =   "https://ccfa.azurewebsites.net/Database/Shop/Edit";

export const environment = {
    appName:                        "Serwis",
    environmentName:                "dev",
    production:                     false,
    version:                        "1.44.0",
    autoLogin:                      true,
  
    clientURL:                      `${CLIENT_URL}`,    
    servicePlatformURL:             `${SERVICE_PLATFORM_URL}`, 
    servicePlatformShopsURL:        `${SERVICE_PLATFORM_SHOPS_URL}`, 
    appURL:                         `${APP_URL}`,
    apiURL:                         `${APP_URL}/api`,
    authApiURL:                     `${APP_URL}/api/auth`,
    partTypesApiURL:                `${APP_URL}/api/part_types`,
    repairTypesApiURL:              `${APP_URL}/api/repair_types`,
    settlementsApiURL:              `${APP_URL}/api/settlements`,
    tariffsApiURL:                  `${APP_URL}/api/tariffs`,

    repairsGroupsPathPart:          `repair_groups`,
    repairsTypesPathPart:           `repair_types`,
    settlementsPathPart:            `settlements`,
    ticketsPathPart:                `tickets`,
    repairsPathPart:                `repairs`,
    partsPathPart:                  `parts`,
    protocolsPathPart:              `protocols`,
    commentsPathPart:               `comments`,
    accountsPathPart:               `accounts`,
    groupsPathPart:                 `named_shop_groups`,
    shopsInGroupsPathPart:          `shops_belonging_to_groups`,
    usersPathPart:                  `users`,
    regionsPathPart:                `regions`,

    settlementsClientPathPart:      `rozliczenia`,
    protocolsClientPathPart:        `protokoly`,
    invoicesPathPart:               `invoices`,
    
    statementsPathPart:             `reports`, 
    countsPathPart:                 `counts`, 

    shopsWithoutGuaranteeDatesPathPart: 'shops_without_guarantee_dates',
    shopsWithoutContextPathPart:        `shops_without_contexts`,
    
    dictionariesApiURL:             `${APP_URL}/api/dictionaries`,
    
    ordersApiURL:                   `${APP_URL}/api/orders`, 
    outcomesApiURL:                 `${APP_URL}/api/outcomes`, 
    partsVariantsApiURL:            `${APP_URL}/api/parts_variants`,
    partsVariantsStatesApiURL:      `${APP_URL}/api/parts_variants`,
    projectsApiURL:                 `${APP_URL}/api/projects`,
    regionsApiURL:                  `${APP_URL}/api/regions`,
    shopsApiURL:                    `${APP_URL}/api/shops`,
    importApiURL:                   `${APP_URL}/api/import`,
    exportApiURL:                   `${APP_URL}/api/export`,
    shiftsApiURL:                   `${APP_URL}/api/shifts`,
    transfersApiURL:                `${APP_URL}/api/transfers`,
    companiesApiURL:                `${APP_URL}/api/companies/with_part_types`,
    testUsersFrio: [    
        // {
        //     firstname: "Bartosz",
        //     lastname: "Super Admin",
        //     roles: [
        //         {
        //             id: 6,
        //             logic_name: "coordinator_wrh",
        //             name: "Koordynator w biurze magazynu",
        //         }
        //     ],
        //     name: "Bartosz - Super admin",
        //     email: "bartosz.staniszewski@frioarte.com.pl",
        //     password: "12345678"
        // },      
        {
            firstname: "Michał",
            lastname: "Koordynator",
            name: "Michał - Jako Koordynator Frioarte",
            email: "michal.aleksiejew@frioarte.pl",
            password: "SzyYomIX"
        },
        {
            firstname: "Julka",
            lastname: "Koordynator",
            name: "Julka - Jako Koordynatorka Frioarte",
            email: "julia.zadrozna@frioarte.pl",
            password: "12345678"
        },
        {
            firstname: "Kacper",
            lastname: "Koordynator",

            name: "Kacper - Jako Koordynator Frioarte",
            email: "kacper.konieczny@frioarte.pl",
            password: "12345678"
        },
        {
            firstname: "Karolina",
            lastname: "Koordynator",
            // roles: [
            //     {
            //         id: 6,
            //         logic_name: "coordinator_wrh",
            //         name: "Koordynator w biurze magazynu",
            //     }
            // ],
            name: "Karolina - Jako Koordynatorka Frioarte",
            email: "karolina.jurczyk@frioarte.pl",
            password: "12345678"
        },
        {
            firstname: "Alicja",
            lastname: "Księgowość",
            name: "Alicja - Jako Księgowość Frioarte",
            email: "alicja.dzieciol@frioarte.pl",
            password: "lmuhix93"
        },
        {
            firstname: "Małgosia",
            lastname: "Księgowość",
            name: "Małgosia - Jako Księgowość Frioarte",
            email: "malgorzata.kuzma@frioarte.pl",
            password: "x5m8px8t"
        },
        {
            firstname: "Karolina",
            lastname: "Księgowość",
            name: "Karolina - Jako Księgowość Frioarte",
            email: "karolina.cichocka@frioarte.pl",
            password: "xr29m84x"
        },
    ],
    testUsersFriwen: [    
        {
            firstname: "Joanna",
            lastname: "Szymendera",
            name: "Joanna - Jako Operatorka Friwen",
            email: "j.szymendera@friwen.pl",
            password: "sJ9QxqbJ"
        },
        {
            firstname: "Krzysztof",
            lastname: "Operator",
            roles: [
                {
                    id: 6,
                    logic_name: "coordinator_wrh",
                    name: "Koordynator w biurze magazynu",
                }
            ],
            name: "Krzysztof - Jako Operator Friwen",
            email: "k.pietraszko@friwen.pl",
            password: "12345678"
        },
        {
            firstname: "Małgosia",
            lastname: "Operator",
            name: "Małgosia - Jako Operatorka Friwen",
            email: "m.brzyska@friwen.pl",
            password: "12345678"
        },
        {
            firstname: "Konrad",
            lastname: "Operator",
            name: "Konrad - Jako Operato Friwen",
            email: "k.pachla@friwen.pl",
            password: "12345678"
        },
        {
            firstname: "Maciek",
            lastname: "Operator",
            name: "Maciek - Jako Operator Friwen",
            email: "m.kudyba@friwen.pl",
            password: "12345678"
        },
        {
            firstname: "Waldek",
            lastname: "Operator",
            name: "Waldek - Jako Operator Friwen",
            email: "w.malek@friwen.pl",
            password: "12345678"
        }
    ],
    companyName: "F R I O"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
