import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { Ticket } from '../../models/ticket';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { Shop } from '../../models/shop';
import { Page } from '../../models/lists/page';
import { Protocol } from '../../models/protocol';
import { TicketHistory } from '../../models/ticket-history';

@Injectable({
    providedIn: 'root'
})
export class TicketsService {

    private readonly apiURL                  = environment.apiURL;
    private readonly ticketsPathPart         = environment.ticketsPathPart;
    private readonly protocolsPathPart       = environment.protocolsPathPart;

    constructor(
        protected readonly httpClient: HttpClient
    ) { }

    public getTicket(ticketId: number): Observable<Ticket> {       
        return this.httpClient.get(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}`,
            {}
        ).pipe(
            map( 
                (ticketResponse: any) => {
                    return this.prepareTicket(ticketResponse.data);
                }
            )
        ) as Observable<Ticket>;
    }

    public getTickets(params: any): Observable<Page<Ticket>> {

        params = Object.assign(
            params,
            {
                limit: 15
            }
        );

        if(!params.column) {
            params.column = "id",
            params.order = "ASC"
        }
        
        return this.httpClient.get(
            `${this.apiURL}/${this.ticketsPathPart}`,
            {
                params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (ticketsResponse: any) => {
                    const tickets: Ticket[] = [];
                    ticketsResponse.data.forEach((ticket: Ticket) => {
                        tickets.push(
                            this.prepareTicket(ticket)
                        );
                    });
                    
                    return {
                        data: tickets,
                        meta: ticketsResponse.meta
                    }
                }
            )
        ) as Observable<Page<Ticket>>;
    }

    public updateProtocol(ticketId: number, protocolId: number, params: any): Observable<boolean> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.protocolsPathPart}/${protocolId}`,
            this.mapNullProperties(params)
        ).pipe(
            map( 
                () => {
                    return true;
                }
            )
        ) as Observable<boolean>;
    }

    public updateTicket(ticketId: number, params: any): Observable<boolean> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}`,
            this.mapNullProperties(params)
        ).pipe(
            map( 
                () => {
                    return true;
                }
            )
        ) as Observable<boolean>;
    }

    public prepareTicket(ticket: Ticket): Ticket {
        return {
            id: Number(ticket.id),
            number: ticket.number,
            shop: this.prepareShop(ticket),
            content: ticket.content,
            repair_date: ticket.repair_date,
            settlement_ids: ticket.settlement_ids ? ticket.settlement_ids : [],
            status: ticket.status,
            protocols: ticket.protocols ? this.prepareProtocols(ticket.protocols, ticket.settlement_ids) : [],
            filing_date: ticket.filing_date ? ticket.filing_date : "",
            newest_repair_date: ticket.newest_repair_date ? String(ticket.newest_repair_date) : "",
            repair_dates: ticket.repair_dates ? ticket.repair_dates : []
        }
    }

    public prepareShop(ticket: Ticket): Shop {
        return {
            id: Number(ticket.shop.id),
            external_id: ticket.shop.external_id,
            mpk: ticket.shop.mpk,
            city: ticket.shop.city,
            street: ticket.shop.street,
            region: ticket.shop.region ? ticket.shop.region : null,
            distance: ticket.shop.distance
        }
    }

    public prepareProtocols(protocolsData: Protocol[] | null, settlement_ids: any): Protocol[] {
        const protocols: Protocol[] = [];

        if(protocolsData) protocolsData.forEach((protocol: Protocol) => {
            protocols.push(this.prepareProtocol(protocol, settlement_ids));
        });

        return protocols;
    }

    public prepareProtocol(protocolData: Protocol, settlement_ids: any): Protocol {
        const protocol: Protocol = {
            id: Number(protocolData.id),
            path: protocolData.path,
            ticket_id: Number(protocolData.ticket_id),
            settlement_ids: protocolData.settlement_ids.map((item) => {
                let status: string | null = null;
                let settlementData: any = null; 

                if(settlement_ids) {
                    settlementData = settlement_ids.find((sItem: any) => sItem.id == item);
                }

                return {
                    id: Number(item),
                    status: settlementData ? this.prepareStatus(settlementData.status) : null,
                    ticket_id: settlementData && settlementData.ticket_id ? Number(settlementData.ticket_id) : -1,
                }
            }),
            is_protocol: protocolData.is_protocol != null ? Boolean(Number(protocolData.is_protocol)) : null,
            is_protocol_human: protocolData.is_protocol_human != null ? Boolean(Number(protocolData.is_protocol_human)) : null,
            is_protocol_system: protocolData.is_protocol_system != null ? Boolean(Number(protocolData.is_protocol_system)) : null,
            loaded: false
        };

        return protocol;
    }

    private prepareStatus(status: string): string {
        switch(status) {
            case "new":
                return "Nowe";
            case "sent":
                return "Wysłane do akceptacji";
            case "rejected":
                return "Odrzucone";
            case "accepted":
                return "Zaakceptowane";
            case "sealed":
                return "Zafakturowane";
        }   

        return "";
    }

    private mapNullProperties(paramsWithNull: any) {
        const paramsWithoutNull = {...paramsWithNull};

        Object.keys(paramsWithoutNull).forEach(key => {
            if (paramsWithoutNull[key] === null || paramsWithoutNull[key] === -1 || paramsWithoutNull[key] === "-1") {
                paramsWithoutNull[key] = '';
            }
        });

        return paramsWithoutNull;
    }
    
    public getTicketHistory(ticketId: number): Observable<TicketHistory[]> {
        
        return this.httpClient.get(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/historical`,
            {}
        ).pipe(
            map( 
                (ticketHistoryResponse: any) => {
                    const ticketHistories: TicketHistory[] = [];

                    ticketHistoryResponse.data.forEach(
                        (item: TicketHistory) => {
                            ticketHistories.push(this.prepareTicketHistory(item));
                        }
                    )
                    
                    return ticketHistories;
                }
            )
        ) as Observable<any>;
    }

    prepareTicketHistory(ticketHistory: TicketHistory): TicketHistory {
        return {
            id: Number(ticketHistory.id),
            user: {
                name: ticketHistory.user.name,
                firstname: ticketHistory.user.firstname,
                lastname: ticketHistory.user.lastname,
                company: {
                    name: ticketHistory.user.company.name
                }
            },
            old_status: ticketHistory.old_status,
            new_status: ticketHistory.new_status,
            description: ticketHistory.description,
            created_at: ticketHistory.created_at
        }
    } 
}
